jQuery(function ($){
	'use strict';

	// Grid breakpoints
	var xs = 360,
		sm = 576,
		md = 768,
		lg = 992,
		xl = 1240;

	var screen = $(window).width();

	// reset screen
	$(window).resize( function() {screen = $(window).width();});

	/* =============================================== */
	/* ============== Links [href="#"] =============== */
	$('a[href="#"]').on('click', function(e){
		e.preventDefault();
	});

	$('a[data-scroll]').on('click', function(e){
		e.preventDefault();
		var target = $(this).attr('href');
		$('html, body').animate({
			scrollTop: $(target).offset().top,
			easing: 'easeInOutQuad'
		}, 400);
	});
	/* =============================================== */
	/* =============================================== */

	/* =============================================== */
	/* ================= On Top Btn ================== */
	$("#ontop").hide().click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});

	$(window).scroll(function () {
		if ($(this).scrollTop() > 300 && screen > md) {
			$('#ontop').show(400);
		} else {
			$('#ontop').hide(200);
		}
	});
	/* =============================================== */
	/* =============================================== */

	/* =============================================== */
	/* ================== Preloader ================== */
	if(navigator.userAgent.indexOf('MSIE')!==-1 || navigator.appVersion.indexOf('Trident/') > -1){
		window.onload = new function() {
			setTimeout(function(){
				$(window).trigger('resize');
				$('body').removeClass('loading');
			}, 400);
		}
	} else {
		$(window).on('load', function () {
			setTimeout(function(){
				$(window).trigger('resize');
				$('body').removeClass('loading');
			}, 400);
		});
	}

	/* =============================================== */
	/* =============================================== */

	/* =============================================== */
	/* ================= Main menu =================== */
	$('.menu li.menu-item-has-children').on('mouseover touchstart touch', function(){
		$(this).children('ul').stop().fadeIn(200);
	});

	$('.menu li.menu-item-has-children > a').each(function(){
		var numOfClicks = 0;
		$(this).on('touchstart touch', function(e) {
			++numOfClicks;
			if (numOfClicks % 2 !==0) {
				e.preventDefault()
			}
		});
	});

	$('.menu li.menu-item-has-children').on('mouseleave', function(){
		$(this).children('ul').stop().fadeOut(200).removeAttr('style');
	});

	$('.menu li.menu-item-has-children > ul').on('mouseleave', function(){
		$(this).stop().fadeOut(200).removeAttr('style');
	});

	$('.menu__burger').on('click', function(e){
		$(this).toggleClass('close')
		$('body').toggleClass('menuShow');
		e.preventDefault();
	});

	$(document).on('keyup', function(e) {
		if (e.keyCode == 27) {
			$('body.menuShow').removeClass('menuShow');
			$('.menu__burger.close').removeClass('close');
		}
	});
	/* =============================================== */
	/* =============================================== */

	/* =============================================== */
	/* ============ Video and img Pop-up ============= */
	$('[data-fancybox]').fancybox({
		youtube : {
			controls : 0,
			showinfo : 0
		},
		vimeo : {
			color : '39f'
		}
	});
	/* =============================================== */
	/* =============================================== */

	/* =============================================== */
	/* ==================== Slider =================== */
	$('.projectsCarousel').slick({
		autoplay: false,
		speed: 800,
		dots: false,
		arrows: true,
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 2,
		centerPadding: '0',
		centerMode: false,
		cssEase: 'ease-in-out',
		appendArrows: '.projectsCarouselNav',

		responsive: [
			{
				breakpoint: xl,
				settings: {
					dots: false,
					arrows: true,
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: lg,
				settings: {
					dots: true,
					arrows: false,
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: sm,
				settings: {
					dots: true,
					arrows: false,
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}).on('init', function(slick){
		$(window).trigger('resize');
	});

	$('.servicesCarousel').each(function(){
		var info = $(this).find('.servicesCarouselInfo'),
			img = $(this).find('.servicesCarouselImg'),
			navClass = info.next('.servicesCarouselNav');

		var count = info.find('.servicesCarouselInfo__item').length,
			width = 100 / count,
			width2 = width;

		navClass.children('.servicesCarouselNav__pregress').children().css('width', width + '%');

		$(info).find('.servicesCarouselInfo__item').each(function(i) {
			var number = i + 1;
			if (i < 9) {
				$(this).find('.servicesCarouselInfo__itemTitle').children('span').text('0' + number);
			} else {
				$(this).find('.servicesCarouselInfo__itemTitle').children('span').text(number);
			}
		});

		info.slick({
			infinite: false,
			adaptiveHeight: true,
			dots: false,
			arrows: true,
			speed: 400,
			centerMode: false,
			centerPadding: '0',
			slidesToShow: 1,
			asNavFor: img,
			cssEase: 'ease-in-out',
			appendArrows: navClass,
			prevArrow: '<button class="prev"><i class="ah-left"></i><b>00</b></button>',
			nextArrow: '<button class="next"><b>02</b><i class="ah-right"></i></button>'
		}).on('afterChange', function(slick, currentSlide){
			var prevCount = navClass.children('.prev').children('b'),
				nextCount = navClass.children('.next').children('b');

			var current = currentSlide.currentSlide;

			if (count >= Number(current)) {
				if (current < 8) {
					nextCount.text('0' + (current + 2));
					prevCount.text('0' + Number(current));
				} else if (current <= 9) {
					nextCount.text(Number(current) + 2);
					prevCount.text('0' + Number(current));
				} else {
					nextCount.text(Number(current) + 2);
					prevCount.text(Number(current));
				}
			}

			console.log();
		});

		navClass.children().on('click', function(){
			if ($(this).is('.next')) {
				width2 = width2 + width;
			} else {
				width2 = width2 - width;
			}

			navClass.children('.servicesCarouselNav__pregress').children().css('width', width2 + '%');
		});

		img.slick({
			infinite: false,
			arrows: false,
			dots: false,
			speed: 400,
			fade: true,
			slidesToShow: 1,
			centerMode: false,
			centerPadding: '0',
			draggable: false,
			// asNavFor: info,
			focusOnSelect: true,
			cssEase: 'ease-in-out'
		});
	}).on('init', function(slick){
		$(window).trigger('resize');
	});

	$('.testimonials').each(function(){
		var text = $(this).find('.testimonialsText'),
			users = $(this).find('.testimonialsUsers');

		text.slick({
			adaptiveHeight: true,
			dots: false,
			arrows: false,
			speed: 400,
			fade: true,
			slidesToShow: 1,
			asNavFor: users,
			cssEase: 'ease-in-out',
			waitForAnimate: false
		});

		users.slick({
			arrows: false,
			dots: false,
			speed: 400,
			centerPadding: '0',
			slidesToShow: 3,
			asNavFor: text,
			focusOnSelect: true,
			cssEase: 'ease-in-out',
			waitForAnimate: false,
			responsive: [
				{
					breakpoint: xl,
					settings: {
						initialSlide: 0,
						centerMode: false,
						slidesToShow: 2
					}
				},
				{
					breakpoint: md,
					settings: {
						slidesToShow: 1
					}
				}
			]
		});
	}).on('init', function(slick){
		$(window).trigger('resize');
	});

	$('.carousel').slick({
		autoplaySpeed: 1600,
		autoplay: true,
		dots: false,
		arrows: false,
		infinite: true,
		speed: 800,
		slidesToShow: 3,
		centerMode: true,
		centerPadding: '0',
		variableWidth: true,
		focusOnSelect: true,
		swipeToSlide: true,
		cssEase: 'ease-in-out',
		// touchThreshold: 20,
		// waitForAnimate: false
	}).on('init', function(slick){
		$(window).trigger('resize');
	});

	$('.teamCarousel').slick({
		autoplaySpeed: 1600,
		autoplay: true,
		dots: false,
		arrows: false,
		infinite: true,
		speed: 800,
		slidesToShow: 5,
		centerMode: true,
		centerPadding: '0',
		cssEase: 'ease-in-out',
		// touchThreshold: 20,
		// waitForAnimate: false
		responsive: [
			{
				breakpoint: xl,
				settings: {
					dots: false,
					arrows: true,
					slidesToShow: 5,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: lg,
				settings: {
					dots: true,
					arrows: false,
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: sm,
				settings: {
					dots: true,
					arrows: false,
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}).on('init', function(slick){
		$(window).trigger('resize');
	});



	// $('.projectsCarouselFilter > a').on('click', function() {
	// 	var filterClass = $(this).attr('data-filter');
	// 	$('.projectsCarousel').slick('slickUnfilter');
	// 	$('.projectsCarousel').slick('slickFilter','div:has('+filterClass+')');

	// 	$(this).addClass('active').siblings('.active').removeClass('active');
	// });

	// $('body').imagesLoaded(function(){});
	/* =============================================== */
	/* =============================================== */

	/* =============================================== */
	/* ================ Masonry grid ================= */
	$('.projectsList').imagesLoaded(function(){
		$('.projectsList').isotope({
			itemSelector: '.projectsItem',
			animationOptions: {
				duration: 200,
				easing: 'ease-in-out',
				queue: true
			},
			layoutMode: 'masonry',
			masonry: {
				gutter: 30
			}
		}).on('layoutComplete', function(slick){
			$(window).trigger('resize');
		});
	});

	$('.projectsFilter').on( 'click', 'a', function() {
		var filterValue = $(this).attr('data-filter');
		$(this).parent().addClass('current-cat').siblings('.current-cat').removeClass('current-cat');
		$('.projectsList').isotope({ filter: filterValue });
	});
	/* =============================================== */
	/* =============================================== */

	/* =============================================== */
	/* =============================================== */
	$('.form-control').on('change', function() {
		if($(this).not(':empty')) {
			$(this).addClass('filled');
		}

		if(!$(this).val()) {
			$(this).removeClass('filled');
		}
	});
	/* =============================================== */
	/* =============================================== */

	/* =============================================== */
	/* ================= Animation =================== */
	new WOW().init({
		offset: '100px',
		mobile: false,
		live: true
	});
	/* =============================================== */
	/* =============================================== */
});